.Toastify__toast {
	min-height: 55px;
	padding: 8px 10px;
	min-width: 320px;
}

.Toastify__toast-container {
	min-width: 320px;
	width: auto;
}

.Toastify__toast--success {
	color: #155724;
	background-color: #d4edda;
	border: 1px solid #c3e6cb;
}

.Toastify__toast--error {
	color: #721c24;
	background-color: #f8d7da;
	border: 1px solid #f5c6cb;
}

@media only screen and (max-width: 480px) {
	.Toastify__toast-container {
		width: 99vw;
		padding: 0;
		right: 2px;
		margin: 0;
		left: unset;
	}
}