/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.btn_blue {
  background: #3F51B5 !important;
  color: white !important;
  border-radius: 4px !important;
  width: 70px;
  height: 30px;
  margin-right: 10px !important
}
.btn_red {
  background: #e73c3c !important;
  color: white !important;
  border-radius: 4px !important;
  width: 70px;
  height: 30px;
  margin-right: 10px !important
}

.ReactModal__Overlay {
  z-index: 2000 !important;
}

/* .MuiPickersPopper-root {
  z-index: 998 !important;
} */